<template>
  <a-dropdown>
    <a> {{ currentTheme }} <a-icon type="down" /> </a>
    <a-menu slot="overlay">
      <a-menu-item @click.native="themeChange('dark')">
        <a href="javascript:;">深色</a>
      </a-menu-item>
      <a-menu-item @click.native="themeChange('light')">
        <a href="javascript:;">浅色</a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { getTheme, setTheme } from "@/utils/auth";

export default {
  name: "HeaderTheme",
  data() {
    return {
      currentTheme:
        getTheme() == "dark" ? "深色" : getTheme() == "light" ? "浅色" : "深色",
    };
  },
  created() {
    this.themeChange(getTheme() || "dark");
  },
  methods: {
    //换主题
    themeChange(type) {
      setTheme(type);
      this.currentTheme =
        getTheme() == "dark" ? "深色" : getTheme() == "light" ? "浅色" : "深色";
      window.document.documentElement.setAttribute("data-theme", type);
    },
  },
};
</script>

<style lang="less"></style>
