import Cookies from "js-cookie";

const COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;

const TokenKey = "jjt_token_new";
const UserIdKey = "jjt_user_id";
const PrincipalIdKey = "jjt_principal_id";
const PrincipalLogoKey = "jjt_principal_logo";
const PrincipalListKey = "jjt_principal_list";
const PrincipalNameKey = "jjt_principal_name";
const PrincipalStateKey = "jjt_principal_state";
const GroupIdKey = "jjt_group_id";
const GroupNameKey = "jjt_group_name";
const GroupLogoKey = "jjt_group_logo";
const GroupListKey = "jjt_group_list";
const RoutesKey = "jjt_routes_new";
const ThemeKey = "jjt_theme";
const NameKey = "jjt_name";
const UserNameKey = "jjt_username";
const RedirectPathKey = "jjt_redirectPath";
const LogoTypeKey = "jjt_logoType";
const CustomerTagKey = "jjt_customer_tag";
const customLogoUrlKey = "jjt_custom_logo";
const PlusRedirect = "jjt_plus_redirect";
const RegionTrackData = "jjt_region_track_data";
const TrackPath = "jjt_track_path";

export function getToken() {
  if (localStorage[TokenKey]) {
    return localStorage[TokenKey];
  }
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  localStorage[TokenKey] = token;
  return Cookies.set(TokenKey, token, { domain: COOKIE_DOMAIN });
}

export function setPlusRedirect() {
  localStorage[PlusRedirect] = location.origin;
  return Cookies.set(PlusRedirect, location.origin, { domain: COOKIE_DOMAIN });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUserId() {
  if (localStorage[UserIdKey]) {
    return localStorage[UserIdKey];
  }
  return Cookies.get(UserIdKey);
}

export function setUserId(id) {
  localStorage[UserIdKey] = id;
  return Cookies.set(UserIdKey, id, { domain: COOKIE_DOMAIN });
}

export function removeUserId() {
  return Cookies.remove(UserIdKey);
}

export function getPrincipalId() {
  if (localStorage[PrincipalIdKey]) {
    return localStorage[PrincipalIdKey];
  }
  return Cookies.get(PrincipalIdKey);
}

export function setPrincipalId(id) {
  localStorage[PrincipalIdKey] = id;
  return Cookies.set(PrincipalIdKey, id, { domain: COOKIE_DOMAIN });
}

export function removePrincipalId() {
  return Cookies.remove(PrincipalIdKey);
}

export function getPrincipalLogo() {
  if (localStorage[PrincipalLogoKey]) {
    return localStorage[PrincipalLogoKey];
  }
  return Cookies.get(PrincipalLogoKey);
}

export function setPrincipalLogo(url) {
  localStorage[PrincipalLogoKey] = process.env.VUE_APP_LIVE_OSS_HOST + url;
  return Cookies.set(
    PrincipalLogoKey,
    process.env.VUE_APP_LIVE_OSS_HOST + url,
    { domain: COOKIE_DOMAIN }
  );
}

export function removePrincipalLogo() {
  return Cookies.remove(PrincipalLogoKey);
}

// export function getPrincipalList() {
//   return Cookies.get(PrincipalListKey)
// }

// export function setPrincipalList(list) {
//   return Cookies.set(PrincipalListKey, list, { domain: COOKIE_DOMAIN })
// }

export function removePrincipalList() {
  return Cookies.remove(PrincipalListKey);
}

export function getPrincipalName() {
  if (localStorage[PrincipalNameKey]) {
    return localStorage[PrincipalNameKey];
  }
  return Cookies.get(PrincipalNameKey);
}

export function setPrincipalName(name) {
  localStorage[PrincipalNameKey] = name;
  return Cookies.set(PrincipalNameKey, name, { domain: COOKIE_DOMAIN });
}

export function removePrincipalName() {
  return Cookies.remove(PrincipalNameKey);
}

export function getPrincipalState() {
  if (localStorage[PrincipalStateKey]) {
    return localStorage[PrincipalStateKey];
  }
  return Cookies.get(PrincipalStateKey);
}

export function setPrincipalState(state) {
  localStorage[PrincipalStateKey] = state;
  return Cookies.set(PrincipalStateKey, state);
}

export function removePrincipalState() {
  return Cookies.remove(PrincipalStateKey);
}

export function getRoutes() {
  return Cookies.get(RoutesKey);
}

export function setRoutes(routes) {
  return Cookies.set(RoutesKey, routes);
}

export function removeRoutes() {
  return Cookies.remove(RoutesKey);
}

export function getTheme() {
  return Cookies.get(ThemeKey);
}

export function setTheme(theme) {
  return Cookies.set(ThemeKey, theme);
}

export function removeTheme() {
  return Cookies.remove(ThemeKey);
}

export function getName() {
  return Cookies.get(NameKey);
}

export function setName(name) {
  return Cookies.set(NameKey, name);
}

export function removeName() {
  return Cookies.remove(NameKey);
}

export function getUserName() {
  if (localStorage[UserNameKey]) {
    return localStorage[UserNameKey];
  }
  return Cookies.get(UserNameKey);
}

export function setUserName(username) {
  localStorage[UserNameKey] = username;
  return Cookies.set(UserNameKey, username);
}

export function removeUserName() {
  return Cookies.remove(UserNameKey);
}

export function getRedirectPath() {
  return Cookies.get(RedirectPathKey);
}

export function setRedirectPath(token) {
  return Cookies.set(RedirectPathKey, token);
}

export function removeRedirectPath() {
  return Cookies.remove(RedirectPathKey);
}

export function getLogoType() {
  if (localStorage[LogoTypeKey]) {
    return localStorage[LogoTypeKey];
  }
  return Cookies.get(LogoTypeKey);
}

export function setLogoType(type) {
  localStorage[LogoTypeKey] = type;
  return Cookies.set(LogoTypeKey, type);
}

export function getCustomLogoUrl() {
  if (localStorage[customLogoUrlKey]) {
    return localStorage[customLogoUrlKey];
  }
  return Cookies.get(customLogoUrlKey);
}

export function setCustomLogoUrl(type) {
  localStorage[customLogoUrlKey] = type;
  return Cookies.set(customLogoUrlKey, type);
}

export function removeLogoType() {
  return Cookies.remove(LogoTypeKey);
}

export function getCustomerTag() {
  if (localStorage[CustomerTagKey]) {
    return localStorage[CustomerTagKey];
  }
  return Cookies.get(CustomerTagKey);
}

export function setCustomerTag(tag) {
  localStorage[CustomerTagKey] = tag;
  return Cookies.set(CustomerTagKey, tag);
}

export function removeCustomerTag() {
  return Cookies.remove(CustomerTagKey);
}

export function getRegionTrackData() {
  try {
    return JSON.parse(localStorage[RegionTrackData] || "{}");
  } catch (error) {
    return {};
  }
}

export function setRegionTrackData(data) {
  Cookies.set(RegionTrackData, data);
  localStorage[RegionTrackData] = JSON.stringify(data);
}

export function getTrackPath() {
  try {
    return JSON.parse(localStorage[TrackPath] || "{}");
  } catch (error) {
    return {};
  }
}

export function setTrackPath(data) {
  Cookies.set(TrackPath, data);
  localStorage[TrackPath] = JSON.stringify(data);
}

export function getGroupId() {
  if (localStorage[GroupIdKey]) {
    return localStorage[GroupIdKey];
  }
  return Cookies.get(GroupIdKey);
}

export function setGroupId(id) {
  localStorage[GroupIdKey] = id;
  return Cookies.set(GroupIdKey, id, { domain: COOKIE_DOMAIN });
}

export function removeGroupId() {
  return Cookies.remove(GroupIdKey);
}

export function getGroupName() {
  if (localStorage[GroupNameKey]) {
    return localStorage[GroupNameKey];
  }
  return Cookies.get(GroupNameKey);
}

export function setGroupName(name) {
  localStorage[GroupNameKey] = name;
  return Cookies.set(GroupNameKey, name, { domain: COOKIE_DOMAIN });
}

export function removeGroupName() {
  return Cookies.remove(GroupNameKey);
}

export function getGroupLogo() {
  if (localStorage[GroupLogoKey]) {
    return localStorage[GroupLogoKey];
  }
  return Cookies.get(GroupLogoKey);
}

export function setGroupLogo(name) {
  localStorage[GroupLogoKey] = name;
  return Cookies.set(GroupLogoKey, name, { domain: COOKIE_DOMAIN });
}

export function removeGroupLogo() {
  return Cookies.remove(GroupLogoKey);
}
