<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer">
    <router-view />
  </a-config-provider>
</template>

<script>
import Cookies from "js-cookie";
import { enquireScreen } from "./utils/util";
import { getPrincipalId, setPlusRedirect } from "./utils/auth";
import { mapState, mapActions, mapMutations } from "vuex";
import themeUtil from "@/utils/themeUtil";
import { getI18nKey } from "@/utils/routerUtil";
import textellipsisResizeMixin from "@/components/TextEllipsis/textellipsisResizeMixin";
import _ from "lodash";

export default {
  name: "App",
  data() {
    return {
      locale: {},
    };
  },
  mixins: [textellipsisResizeMixin],
  created() {
    this.setHtmlTitle();
    this.setLanguage(this.lang);
    // enquireScreen((isMobile) => this.setDevice(isMobile));

    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      let state = Object.assign(
        {},
        this.$store.state,
        JSON.parse(decodeURIComponent(localStorage.getItem("store")))
      );
      this.$store.replaceState(state);
    }
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("unload", () => {
      localStorage.setItem(
        "store",
        encodeURIComponent(JSON.stringify(this.$store.state))
      );
    });
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(
        "store",
        encodeURIComponent(JSON.stringify(this.$store.state))
      );
    });

    this.initFixed();
    // 不是在登录页，加载平台设置
    if (!window.location.href.includes("/login")) {
      this.getPlatformSetting(getPrincipalId());
    }
  },
  mounted() {
    this.setWeekModeTheme(this.weekMode);
    // 设置plus重定向域名
    setPlusRedirect();
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val);
    },
    lang(val) {
      this.setLanguage(val);
      this.setHtmlTitle();
    },
    $route() {
      this.setHtmlTitle();
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    },
    "theme.mode": function (val) {
      let closeMessage = this.$message.loading(
        `您选择了主题模式 ${val}, 正在切换...`
      );
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage);
    },
    "theme.color": function (val) {
      let closeMessage = this.$message.loading(
        `您选择了主题色 ${val}, 正在切换...`
      );
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage);
    },
    layout: function () {
      window.dispatchEvent(new Event("resize"));
    },
  },
  computed: {
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),
  },
  methods: {
    ...mapActions("auth", ["getPlatformSetting"]),
    ...mapMutations("setting", ["setDevice"]),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add("week-mode");
      } else {
        document.body.classList.remove("week-mode");
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
      switch (lang) {
        case "CN":
          this.locale =
            require("ant-design-vue/es/locale-provider/zh_CN").default;
          break;
        case "HK":
          this.locale =
            require("ant-design-vue/es/locale-provider/zh_TW").default;
          break;
        case "US":
        default:
          this.locale =
            require("ant-design-vue/es/locale-provider/en_US").default;
          break;
      }
    },
    setHtmlTitle() {
      // const route = this.$route;
      // const key =
      //   route.path === "/"
      //     ? "home.name"
      //     : getI18nKey(route.matched[route.matched.length - 1].path);
      // document.title = process.env.VUE_APP_NAME + " | " + this.$t(key);

      document.title = ''
    },
    popContainer() {
      return document.getElementById("popContainer");
    },
    // 修复fixed方法为四舍五入
    initFixed() {
      Number.prototype.toFixed = function (n) {
        return _.round(this, n);
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_handle.scss";
body {
  padding: 0;
}
::selection {
  background: #0291d3 !important;
}
::-webkit-scrollbar {
  width: 4px;
}
ul::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #565d72;
}
::-webkit-scrollbar-corner {
  @include background_color("background_color42");
}
::-webkit-scrollbar-track {
  border-radius: 0;
  @include background_color("background_color5");
}
// 日历弹窗样式更改
.ant-calendar-picker-container {
  .ant-calendar {
    border: none;
    border-radius: 16px;
    overflow: hidden;
    @include box_shadow("box_shadow4");
    @include background_color("background_color51");
  }
  .ant-calendar-month-panel,
  .ant-calendar-year-panel,
  .ant-calendar-decade-panel {
    @include background_color("background_color51");
  }
  .ant-calendar-month-panel-header,
  .ant-calendar-year-panel-header {
    @include background_color("background_color51");
  }
  .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-month,
  .ant-calendar-decade-panel-decade,
  .ant-calendar-decade-panel-century {
    @include font_color("font_color21");
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    @include font_color("font_color17");
    @include background_color("background_color13");
    border-radius: 8px;
  }
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-prev-century-btn:hover::before,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-prev-decade-btn:hover::before,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-prev-year-btn:hover::before,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-prev-century-btn:hover::after,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-prev-decade-btn:hover::after,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-prev-year-btn:hover::after,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-next-century-btn:hover::before,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-next-decade-btn:hover::before,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-next-year-btn:hover::before,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-next-century-btn:hover::after,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-next-decade-btn:hover::after,
  .ant-calendar-month-panel-header
    .ant-calendar-month-panel-next-year-btn:hover::after {
    @include border_color("border_color17");
  }
  .ant-calendar-month-panel-selected-cell.ant-calendar-month-panel-cell-disabled
    .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell.ant-calendar-year-panel-cell-disabled
    .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell.ant-calendar-decade-panel-cell-disabled
    .ant-calendar-decade-panel-decade {
    background: none !important;
  }
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-cell-disabled .ant-calendar-year-panel-year,
  .ant-calendar-year-panel-cell-disabled .ant-calendar-year-panel-year:hover {
    background: none !important;
    @include font_color("font_color3");
  }
  .ant-calendar-month-panel-year-select-content,
  .ant-calendar-year-panel-year {
    @include font_color("font_color21");
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
    @include font_color("font_color21");
  }
  .ant-calendar-month-panel-header,
  .ant-calendar-year-panel-header,
  .ant-calendar-decade-panel-header {
    border: none;
  }
  .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover {
    @include background_color("background_color13");
    @include font_color("font_color25");
    border-radius: 8px;
  }
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover {
    background: none !important;
  }
  .ant-calendar-header {
    @include font_color("font_color20");
    @include background_color("background_color62");
  }
  .ant-calendar-header .ant-calendar-century-select,
  .ant-calendar-header .ant-calendar-decade-select,
  .ant-calendar-header .ant-calendar-year-select,
  .ant-calendar-header .ant-calendar-month-select {
    @include font_color("font_color21");
  }
  .ant-calendar-body {
    @include font_color("font_color21");
    // @include background_color("background_color1");
  }
  .ant-calendar-date {
    @include font_color("font_color21");
    background: none !important;
  }
  .ant-calendar-header {
    // @include border_color("border_color6");
  }
  .ant-calendar .ant-calendar-today-btn:only-child,
  .ant-calendar .ant-calendar-clear-btn:only-child {
    @include font_color("font_color26");
  }
  .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    @include background_color("background_color13");
  }
  .ant-calendar-picker-container .ant-calendar-selected-day .ant-calendar-date {
    @include font_color("font_color3");
  }
  .ant-calendar-week-number .ant-calendar-body tr:hover,
  .ant-calendar-date:hover {
    @include background_color("background_color13");
    @include font_color("font_color25");
  }
  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-in-range-cell::before {
    @include background_color("background_color34");
    @include font_color("font_color25");
    height: 24px;
    margin-top: -1px;
  }
  .ant-calendar-disabled-cell.ant-calendar-selected-day.ant-calendar-selected-start-date
    .ant-calendar-date {
    width: 24px;
    border-radius: 2px;
    &::before {
      left: -1px;
    }
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    width: 24px;
    padding: 0;
    border-radius: 2px;
    &::before {
      left: -1px;
    }
  }
  .ant-calendar-cell.ant-calendar-in-range-cell .ant-calendar-date {
    @include font_color("font_color25");
  }
  .ant-calendar-today .ant-calendar-date {
    @include border_color("border_color11");
  }
  .ant-calendar-disabled-cell .ant-calendar-date {
    background: none;
    @include font_color("font_color3");
  }
  .ant-calendar-disabled-cell .ant-calendar-date:hover {
    background: none !important;
  }
  .ant-calendar-range .ant-calendar-month-panel-body,
  .ant-calendar-range .ant-calendar-year-panel-body,
  .ant-calendar-range .ant-calendar-decade-panel-body {
    @include border_color("border_color18");
  }
  .ant-calendar-range .ant-calendar-body {
    @include border_color("border_color18");
    @include background_color("background_color51");
  }
  .ant-calendar-input-wrap {
    @include background_color("background_color51");
    @include font_color("font_color21");
    @include border_color("border_color18");
  }
  .ant-calendar-input-wrap .ant-calendar-input,
  .ant-calendar-range .ant-calendar-time-picker-input,
  .ant-calendar-range .ant-calendar-input {
    @include background_color("background_color51");
    @include font_color("font_color22");
  }
  .ant-calendar-range-middle {
    @include font_color("font_color21");
  }
  .ant-calendar-time-picker-btn {
    @include font_color("font_color26");
  }
  .ant-calendar-ok-btn {
    @include background_color("background_color13");
    @include border_color("border_color4");
    @include font_color("font_color17");
  }
  .ant-calendar-time-picker-combobox {
    @include background_color("background_color51");
  }
  .ant-calendar-time-picker {
    @include background_color("background_color51");
  }
  .ant-calendar-time-picker-select
    li.ant-calendar-time-picker-select-option-selected {
    @include background_color("background_color13");
    @include font_color("font_color17");
  }
  .ant-calendar-time-picker-select li {
    @include font_color("font_color21");
    &:hover {
      @include font_color("font_color17");
      @include background_color("background_color13");
    }
  }
  .ant-calendar-time .ant-calendar-day-select {
    @include font_color("font_color21");
  }
  .ant-calendar-footer,
  .ant-calendar-range-left .ant-calendar-time-picker-inner,
  .ant-calendar-range-right .ant-calendar-time-picker-inner,
  .ant-calendar-time-picker-select,
  .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
    @include border_color("border_color2");
  }
}
// 下拉框弹窗样式修改

#popContainer {
  // 单选框
  .ant-radio-inner {
    background: none !important;
    border-width: 2px;
    @include border_color("border_color7");
  }
  .ant-radio-checked .ant-radio-inner {
    @include border_color("border_color4");
  }
  .ant-radio-inner::after {
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    @include background_color("background_color13");
  }
  .ant-radio-wrapper {
    @include font_color("font_color3");
    line-height: 32px;
  }
  .ant-radio-wrapper-checked.ant-radio-wrapper {
    @include font_color("font_color1");
  }

  .ant-select-dropdown {
    @include background_color("background_color51");
    border-radius: 16px;
    overflow: hidden;
    @include box_shadow("box_shadow4");
  }
  .ant-select-dropdown-menu {
    padding: 0;
  }
  .ant-select-dropdown-menu-item {
    padding: 11px 12px;
    height: 44px;
    @include font_color("font_color2");
  }
  .ant-select-dropdown-menu-item-selected {
    @include background_color("background_color5");
    @include font_color("font_color26");
  }
  .ant-select-dropdown-menu-item:hover:not(
      .ant-select-dropdown-menu-item-disabled
    ) {
    @include background_color("background_color5");
    @include font_color("font_color26");
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    @include font_color("font_color38");
  }
  .ant-select-clear-icon {
    @include background_color("background_color50");
    @include font_color("font_color22");
  }
  .modal .ant-select-clear-icon {
    @include background_color("background_color1");
  }

  // tree-select
  .ant-select-tree li .ant-select-tree-node-content-wrapper {
    @include font_color("font_color22");
  }
  .ant-select-tree-checkbox-inner {
    @include background_color("background_color51");
    @include border_color("border_color33");
  }
  .ant-select-tree-checkbox-indeterminate
    .ant-select-tree-checkbox-inner::after {
    @include background_color("background_color34");
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    @include background_color("background_color34");
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    @include border_color("border_color31");
  }
  .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
    background: none !important;
  }

  // 联动选择框
  .ant-cascader-menus {
    background: red;
    @include background_color("background_color51");
  }
  .ant-cascader-menu-item,
  .ant-cascader-menu-item-expand-icon {
    @include font_color("font_color2");
  }
  .ant-cascader-menu-item-active,
  .ant-cascader-menu-item:hover {
    @include background_color("background_color5");
    @include font_color("font_color26");
  }
  .ant-cascader-menu {
    @include border_color("border_color16");
  }
}
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  @include background_color("background_color1");
}
.ant-spin-container::after {
  @include background_color("background_color5");
}
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  @include background_color("background_color42");
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  height: 100%;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  @include background_color("background_color1");
}
.ant-table-tbody > tr.ant-table-row .table-show-icon {
  display: none;
}
.ant-table-tbody > tr.ant-table-row:hover .table-show-icon {
  display: inline-block;
}
.ant-table-tbody > tr.ant-table-row:hover .table-show-icon.active {
  display: none;
}
.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: none !important;
}
.hover-table {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 7px 7px;
    border: none;
    @include border_color("border_color6");
  }
  .ant-table-thead > tr > th {
    @include background_color("background_color2");
    @include font_color("font_color2");
  }
  .ant-table-fixed-header
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body {
    @include background_color("background_color5");
  }
  .ant-table {
    @include font_color("font_color3");
    @include background_color("background_color1");
  }
  .ant-table-header {
    background: none !important;
  }
  .ant-table-placeholder {
    @include font_color("font_color3");
    border: none;
    @include background_color("background_color1");
  }
  .table-row-active {
    @include background_color("background_color42");
  }
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    @include background_color("background_color42");
  }
  .ant-table-tbody .rowActive {
    @include background_color("background_color42");
  }
}
.ant-drawer-content {
  @include font_color("font_color1");
  // opacity: 0.9;
  background: none !important;
}
.ant-drawer-wrapper-body {
  // @include background_color("background_color28");
  background: none !important;
}
.ant-drawer {
  z-index: 999 !important;
  .area-input .ant-input {
    @include background_color("background_color33");
    @include font_color("font_color2");
    @include border_color("border_color1");
  }

  .aft-input.opacity.ant-input {
    height: 44px;
    border: none !important;
    background: none !important;
    border-radius: 8px;
    @include font_color("font_color21");
    outline: medium;
    &::placeholder {
      @include font_color("font_color38");
    }
    &:focus {
      @include font_color("font_color20");
      border: none !important;
      box-shadow: none !important;
    }
  }

  .drawer.aft-input.ant-input,
  .drawer.aft-input.ant-input-affix-wrapper .ant-input {
    @include background_color("background_color2");
    @include font_color("font_color52");
    border-radius: 8px;
    border: none;
    &::placeholder {
      @include font_color("font_color53");
    }
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .drawer.aft-range-picker.ant-calendar-picker {
    .ant-input {
      @include background_color("background_color2");
      @include font_color("font_color52");
      border-radius: 8px;
      border: none;
      .ant-calendar-range-picker-input {
        &::placeholder {
          @include font_color("font_color53");
        }
        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }
      .ant-calendar-range-picker-separator {
        line-height: 30px;
        @include font_color("font_color23");
      }
      .ant-calendar-picker-clear {
        @include background_color("background_color2");
        @include font_color("font_color23");
      }
    }
  }
  .drawer.aft-checkbox.ant-checkbox-group {
    .ant-checkbox-inner {
      @include background_color("background_color85");
      @include border_color("border_color30");
    }
    .ant-checkbox + span {
      @include font_color("font_color52");
    }
    .ant-checkbox-checked::after {
      @include border_color("border_color11");
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        @include background_color("background_color34");
        @include border_color("border_color11");
        &::after {
          @include border_color("border_color31");
        }
      }
    }
    .ant-checkbox-wrapper-disabled {
      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          @include border_color("border_color32");
          @include background_color("background_color14");
          &::after {
            @include border_color("border_color32");
          }
        }
      }

      .ant-checkbox-disabled.ant-checkbox + span {
        @include font_color("font_color54");
      }
    }
  }
  .drawer.aft-switch.ant-switch {
    @include background_color("background_color86");
    &.ant-switch-checked {
      @include background_color("background_color34");
    }
  }
  .drawer.aft-upload.ant-upload-picture-card-wrapper {
    .ant-upload.ant-upload-select-picture-card {
      background: none !important;
      @include border_color("border_color32");
      .ant-upload {
        @include font_color("font_color2");
      }
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      @include border_color("border_color32");
    }
  }
  .drawer.aft-upload-list {
    .ant-upload-list {
      @include font_color("font_color52");
    }
    .ant-upload-list-item-info .anticon-loading,
    .ant-upload-list-item-info .anticon-paper-clip {
      @include font_color("font_color56");
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background: none !important;
    }
    .ant-upload-list-item-card-actions .anticon {
      @include font_color("font_color2");
    }
    .ant-upload-list-item-error,
    .ant-upload-list-item-error .ant-upload-list-item-name,
    .ant-upload-list-item-error .anticon-paper-clip {
      @include font_color("font_color57");
    }
    .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
      @include font_color("font_color57");
    }
    .ant-progress-inner {
      @include background_color("background_color34");
    }
  }
  .drawer.aft-input.ant-input-group-wrapper {
    .ant-input-wrapper.ant-input-group {
      height: 40px;
      .ant-input-group-addon {
        @include background_color("background_color2");
        @include font_color("font_color52");
        border: none;
        border-right: 1px solid #3c4252;
        border-radius: 8px 0 0 8px;
        .ant-select {
          @include font_color("font_color52");
          .ant-select-selection {
            @include background_color("background_color2");
            @include font_color("font_color52");
            border: none;
          }
          .ant-select-arrow {
            @include font_color("font_color52");
          }
          .ant-select-selection--single {
            height: 100%;
            margin: 0;
          }
          .ant-select-selection__rendered {
            height: 100%;
            display: flex;
            align-items: center;
          }
          .ant-select-selection-selected-value {
            height: 14px;
            line-height: 14px;
          }
        }
      }

      .ant-input {
        height: 40px;
        @include background_color("background_color2");
        @include font_color("font_color52");
        border-radius: 0 8px 8px 0;
        border: none;
        &::placeholder {
          @include font_color("font_color53");
        }
        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .drawer.aft-select.ant-select {
    @include font_color("font_color52");
    min-height: 40px;
    .ant-select-selection {
      @include background_color("background_color2");
      border: none;
      border-radius: 8px;
    }
    .ant-select-arrow {
      @include font_color("font_color52");
    }
    .ant-select-selection--single {
      height: 100%;
    }
    .ant-select-selection__rendered {
      height: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
    }
    .ant-select-selection-selected-value {
      height: 14px;
      line-height: 14px;
    }
    .ant-select-selection.ant-select-selection--multiple {
      height: 100%;
      min-height: 40px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
      @include background_color("background_color87");
      @include border_color("border_color30");
      @include font_color("font_color52");
      border-radius: 4px;
      border: 1px solid;
      height: 28px;
      line-height: 26px;
    }
    .ant-select-selection--multiple .ant-select-selection__choice__remove {
      @include font_color("font_color52");
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      @include font_color("font_color53");
    }
  }

  // drawer里的单选框 -- 开始
  .ant-radio-inner {
    background: none !important;
    border-width: 2px;
    @include border_color("border_color7");
  }
  .ant-radio-checked .ant-radio-inner {
    @include border_color("border_color4");
  }
  .ant-radio-inner::after {
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    @include background_color("background_color13");
  }
  .ant-radio-wrapper {
    @include font_color("font_color3");
    line-height: 32px;
  }
  .ant-radio-disabled + span {
    @include font_color("font_color54");
  }
  .ant-radio-wrapper-checked.ant-radio-wrapper {
    @include font_color("font_color1");
  }
  // drawer里的单选框 -- 结束

  .drawer2.aft-select.ant-select {
    @include font_color("font_color52");
    .ant-select-selection {
      @include background_color("background_color89");
      border: none;
    }
    .ant-select-arrow {
      @include font_color("font_color52");
    }
    .ant-select-selection--single {
      height: 100%;
    }
    .ant-select-selection__rendered {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .ant-select-selection-selected-value {
      height: 14px;
      line-height: 14px;
    }
  }
  .drawer2.aft-slider.ant-slider.ant-slider-disabled {
    margin-left: 0;
    .ant-slider-rail {
      @include background_color("background_color14");
    }
    .ant-slider-track {
      @include background_color("background_color34");
    }
    .ant-slider-handle,
    .ant-slider-dot {
      @include background_color("background_color90");
      @include border_color("border_color4");
    }
  }

  .drawer2.aft-input.ant-input,
  .drawer2.aft-input.ant-input-number {
    @include background_color("background_color89");
    @include font_color("font_color52");
    border-radius: 8px;
    border: none;
    &::placeholder {
      @include font_color("font_color53");
    }
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input-wrap {
      height: 100%;
      input {
        height: 100%;
      }
    }
    .ant-input-number-focused {
      border: none !important;
      box-shadow: none !important;
    }
  }

  // -- table相关 start --

  .aft-table.default.ant-table-wrapper {
    border-radius: 16px;
    overflow: hidden;
    .ant-table-tbody > tr,
    .ant-table-tbody > tr > td {
      border-top: 1px solid #323744;
      @include border_color("border_color16");
      @include font_color("font_color21");
      @include background_color("background_color2");
    }
    .ant-table-thead > tr > th {
      @include background_color("background_color5");
      @include font_color("font_color21");
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      @include background_color("background_color42");
      @include font_color("font_color26");
    }
    .ant-table-tbody .rowActive {
      @include background_color("background_color42");
    }
    .ant-table-thead
      > tr
      > th
      .ant-table-column-sorter
      .ant-table-column-sorter-inner-full {
      margin-top: -0.55em;
    }
    .ant-table-thead
      > tr
      > th
      .ant-table-column-sorter
      .ant-table-column-sorter-inner
      .ant-table-column-sorter-up.on,
    .ant-table-thead
      > tr
      > th
      .ant-table-column-sorter
      .ant-table-column-sorter-inner
      .ant-table-column-sorter-down.on {
      @include font_color("font_color26");
    }
    // .ant-table-header {
    //   margin-bottom: 0px !important;
    // }
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      background: none !important;
    }
    .ant-table-placeholder {
      @include font_color("font_color3");
      border: none;
      @include background_color("background_color2");
    }
    .ant-table-thead > tr > th {
      border-bottom: none;
    }
  }

  .aft-table.default.ant-table-wrapper.x-scroll {
    .ant-table-fixed-left,
    .ant-table-fixed-right {
      @include background_color("background_color50");

      .ant-table-header {
        margin-bottom: 0px !important;
      }
    }
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      @include background_color("background_color50");
    }
  }
  // -- table相关 end --

  // -- pagination相关 start --
  .aft-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      margin: 0 20px 0 0 !important;
      width: 36px !important;
      height: 36px !important;
      line-height: 36px !important;
      @include background_color("background_color50");
      border-radius: 8px !important;
    }

    .ant-pagination-next {
      margin: 0 0 0 10px !important;
    }

    .ant-pagination-item {
      margin-right: 0 !important;
      margin: 0 0 0 -10px !important;
      min-width: 46px !important;
      height: 36px !important;
      line-height: 36px !important;
      @include background_color("background_color50");
      border-color: #2b2f3b;
      border-radius: 8px !important;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin: 0 0 0 -10px !important;
      width: 46px !important;
      height: 36px !important;
      line-height: 36px !important;
      @include background_color("background_color50");
      border-radius: 8px !important;
    }
  }

  .ant-pagination-item a,
  .ant-pagination-prev a,
  .ant-pagination-next a {
    @include font_color("font_color3");
  }
  .ant-pagination-item-active {
    background: none;
    border: none;
    a {
      @include font_color("font_color5");
    }
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    @include font_color("font_color5");
  }
  .ant-pagination-item-ellipsis {
    @include font_color("font_color5");
  }
  // -- pagination相关 end --
}

.aft-switch.ant-switch {
  @include background_color("background_color86");
  &.ant-switch-checked {
    @include background_color("background_color34");
  }
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  @include border_right_color("border_color13");
  @include border_bottom_color("border_color13");
  @include border_top_color("border_color13");
  @include border_left_color("border_color13");
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  @include border_right_color("border_color13");
  @include border_bottom_color("border_color13");
  @include border_top_color("border_color13");
  @include border_left_color("border_color13");
}
.ant-popover-inner,
.ant-popover-inner-content {
  background: #3c4252 !important;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.12),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 6px 16px -8px rgba(0, 0, 0, 0.32) !important;
  border-radius: 8px !important;
}
//dropdown
.aft-menu.ant-dropdown-menu {
  @include background_color("background_color51");
  padding: 0 !important;
  border-radius: 16px !important;
}

.aft-menu.ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
  @include background_color("background_color5");
  @include font_color("font_color26");
}
.ant-dropdown-menu {
  @include background_color("background_color2");
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  @include font_color("font_color2");
}

.ant-dropdown-menu-submenu-title:hover {
  @include background_color("background_color6");
}
.ant-dropdown-menu-item:hover {
  background-color: transparent !important;
  color: #ffca5c !important;
}
.ant-dropdown-menu-item-divider {
  margin: 0 !important;
  @include background_color("background_color67");
}
// 表格阴影
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left,
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  @include box_shadow("box_shadow3");
}
.ant-table-fixed-left {
  @include box_shadow("box_shadow1");
}
.ant-table-fixed-right {
  @include box_shadow("box_shadow2");
}

.icon-select .ant-select-search--inline .ant-select-search__field {
  padding-left: 20px;
  // @include background_color("background_color47");
}
.ant-modal-content {
  background-color: #1a1d26;
  border-radius: 8px;
  overflow: hidden;

  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-form-explain {
    margin-left: 12px;
  }

  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-confirm-content {
      @include font_color("font_color20");
    }
  }

  .ant-input {
    @include background_color("background_color1");
    @include font_color("font_color20");
    @include border_color("border_color13");
  }
  .aft-input.modal.ant-input {
    padding: 4px 20px;
  }
  .aft-input.modal2.ant-input {
    padding: 4px 20px;
    @include background_color("background_color2");
  }
  textarea.ant-input {
    border-radius: 8px !important;
  }
}

// -- 月份日历选择相关 start --
.aft-month-picker.default.ant-calendar-picker,
.aft-range-picker.default.ant-calendar-picker {
  .ant-input {
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 12px;
    @include font_color("font_color41");
    @include background_color("background_color34");
    font-weight: bold;
    > input,
    > span {
      font-weight: bold;
    }
  }
  .ant-calendar-range-picker-separator {
    @include font_color("font_color41");
    line-height: 30px;
  }
}

.modal {
  .ant-calendar-range-picker-input {
    border-radius: 8px !important;
    &::placeholder {
      @include font_color("font_color38");
    }
    &:focus {
      box-shadow: none;
    }
  }
  &.ant-calendar-picker:focus
    .ant-calendar-picker-input:not(.ant-input-disabled) {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .ant-calendar-picker-input {
    border-radius: 8px !important;
  }

  .ant-calendar-picker-clear {
    background: none;
    @include background_color("background_color1");
    @include font_color("font_color41");
  }
  .ant-calendar-picker-icon {
    @include font_color("font_color3");
  }
  .ant-calendar-range-picker-separator {
    @include font_color("font_color41");
    line-height: 30px;
  }
}
.ant-form-item-label > label {
  @include font_color("font_color23");
}
// -- 月份日历选择相关 end --

// -- checkbox相关 start --
.aft-checkbox.modal.ant-checkbox-group {
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border-radius: 8px;
    border: none;
    @include background_color("background_color1");
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    @include background_color("background_color34");
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    @include border_color("border_color20");
    left: 31%;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-wrapper {
    @include font_color("font_color23");
    font-size: 14px;
  }
}
// -- checkbox相关 end --

// -- 下拉框相关 start --
.aft-select.default.ant-select {
  @include font_color("font_color28");
  .ant-select-selection {
    @include background_color("background_color50");
    border: none !important;
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color21");
  }
}
.aft-select.card.ant-select {
  @include font_color("font_color28");
  .ant-select-selection {
    @include background_color("background_color89");
    border: none !important;
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color21");
  }
}
.aft-select.blue.ant-select {
  @include font_color("font_color28");
  .ant-select-selection {
    @include background_color("background_color72");
    border: none !important;
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color21");
  }
}
.aft-select.modal.ant-select {
  @include font_color("font_color28");
  .ant-select-selection {
    @include background_color("background_color1");
    border-radius: 8px;
    border: none !important;
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color21");
  }

  .ant-select-arrow {
    @include font_color("font_color22");
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    @include font_color("font_color38");
    padding-left: 10px;
  }
  .ant-select-arrow {
    @include font_color("font_color22");
  }
  .ant-select-selection__rendered {
    margin-left: 20px;
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }
  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 7px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    @include font_color("font_color52");
    @include background_color("background_color87");
    @include border_color("border_color30");
    border-radius: 4px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    @include font_color("font_color52");
  }
}

// -- 展开时
.aft-select.default.ant-select.ant-select-open {
  @include font_color("font_color20");
  .ant-select-selection {
    @include background_color("background_color42");
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color20");
  }
}
.aft-select.blue.ant-select.ant-select-open {
  @include font_color("font_color20");
  .ant-select-selection {
    @include background_color("background_color72");
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color20");
  }
}
// -- 下拉框相关 end --

// -- input相关 start --
.ant-layout {
  .aft-input.default.ant-input {
    height: 40px;
    border: none !important;
    border-radius: 8px;
    @include font_color("font_color21");
    outline: medium;
    @include background_color("background_color61");
    &::placeholder {
      @include font_color("font_color38");
    }
    &:focus {
      @include font_color("font_color20");
      @include background_color("background_color5");
    }
  }
  .aft-search.ant-input-search.ant-input-affix-wrapper {
    > input {
      height: 40px;
      border: none !important;
      border-radius: 8px;
      @include font_color("font_color21");
      outline: medium;
      @include background_color("background_color61");
    }
    > input:focus {
      @include font_color("font_color20");
      @include background_color("background_color5");
    }
  }
  .aft-input.modal.ant-input {
    border: none;
    @include background_color("background_color1");
    border-radius: 8px;
    &::placeholder {
      @include font_color("font_color38");
    }
    &:focus {
      box-shadow: none;
    }
  }
}
// -- input相关 end --

// -- progress相关 start --
.ant-layout {
  .ant-progress-inner {
    @include background_color("background_color73");
  }
  .aft-progress.default {
    .ant-progress-inner {
      background-color: transparent !important;
      .ant-progress-circle-trail {
        stroke: rgba(255, 255, 255, 0.1) !important;
      }
    }
  }
}
// -- progress相关 end --

// modal弹框
.ant-modal-root {
  .ant-modal-header {
    background: #1a1d26;
    border-color: #3a3a3a;
  }
  .ant-modal-title,
  .ant-modal-close-x {
    color: rgba($color: #ffffff, $alpha: 0.88);
  }
  .ant-modal-footer {
    border-color: #3a3a3a;
  }
  .ant-modal-close-x {
    color: rgba($color: #ffffff, $alpha: 0.88);
  }
}

// -- message 相关 start --
.ant-message-notice-content {
  background: #292929 !important;
  color: rgba($color: #ffffff, $alpha: 0.88);
}
.ant-message-warning .anticon {
  color: #ff9026 !important;
  font-size: 14px !important;
}
.ant-message-success .anticon {
  font-size: 14px !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-menu-vertical > .ant-menu-item,
/deep/ .ant-menu-vertical-left > .ant-menu-item,
/deep/ .ant-menu-vertical-right > .ant-menu-item,
/deep/ .ant-menu-inline > .ant-menu-item,
/deep/ .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
/deep/ .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 50px;
}

.ant-layout /deep/ {
  // 最新版本样式 -- start --------------------------------------------
  // 侧边栏
  .ant-layout-sider-children {
    overflow-y: auto;
    height: calc(100vh - 76px);
  }
  // -- table相关 start --

  .aft-table.default {
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
      .anticon-filter:hover,
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
      .ant-table-filter-icon:hover {
      background: none !important;
    }
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
      .anticon-filter,
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
      .ant-table-filter-icon {
      background: none !important;
    }
  }
  .aft-table.default.ant-table-wrapper {
    border-radius: 16px;
    overflow: hidden;
    .ant-table-tbody > tr,
    .ant-table-tbody > tr > td {
      border-top: 1px solid #323744;
      @include border_color("border_color16");
      @include font_color("font_color21");
      @include background_color("background_color2");
    }
    .ant-table-thead > tr > th {
      @include background_color("background_color5");
      @include font_color("font_color21");
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      @include background_color("background_color42");
      @include font_color("font_color26");
    }
    .ant-table-tbody .rowActive {
      @include background_color("background_color42");
    }
    .ant-table-thead
      > tr
      > th
      .ant-table-column-sorter
      .ant-table-column-sorter-inner-full {
      margin-top: -0.55em;
    }
    .ant-table-thead
      > tr
      > th
      .ant-table-column-sorter
      .ant-table-column-sorter-inner
      .ant-table-column-sorter-up.on,
    .ant-table-thead
      > tr
      > th
      .ant-table-column-sorter
      .ant-table-column-sorter-inner
      .ant-table-column-sorter-down.on {
      @include font_color("font_color26");
    }
    // .ant-table-header {
    //   margin-bottom: 0px !important;
    // }
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      background: none !important;
    }
  }

  .aft-table.default.ant-table-wrapper.x-scroll {
    .ant-table-header {
      margin-bottom: 0px !important;
      overflow: hidden !important;
    }
    .ant-table-fixed-left,
    .ant-table-fixed-right {
      @include background_color("background_color50");

      .ant-table-header {
        margin-bottom: 0px !important;
        overflow: hidden !important;
      }
    }
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      @include background_color("background_color50");
    }
  }
  // -- table相关 end --

  // -- pagination相关 start --
  .aft-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      margin: 0 20px 0 0;
      width: 36px;
      height: 36px;
      line-height: 36px;
      @include background_color("background_color50");
      border-radius: 8px;
    }

    .ant-pagination-next {
      margin: 0 0 0 10px;
    }

    .ant-pagination-item {
      margin-right: 0;
      margin: 0 0 0 -10px;
      min-width: 46px;
      height: 36px;
      line-height: 36px;
      @include background_color("background_color50");
      border-color: #2b2f3b;
      border-radius: 8px;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin: 0 0 0 -10px;
      width: 46px;
      height: 36px;
      line-height: 36px;
      @include background_color("background_color50");
      border-radius: 8px;
    }
  }
  // -- pagination相关 end --

  // 最新版本样式 -- end --------------------------------------------

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }
  ul::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #565d72;
  }
  ::-webkit-scrollbar-corner {
    @include background_color("background_color42");
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    @include background_color("background_color5");
  }
  .ant-layout.ant-layout-has-sider {
    @include background_color("background_color45");
  }
  .admin-layout-content {
    @include background_color("background_color1");
    border-radius: 26px 26px 0 0;
    border-right: 14px solid rgba(0, 0, 0, 0);
    @include border_color("border_color19");
  }
  .ant-layout-sider {
    @include background_color("background_color45");
  }

  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    @include background_color("background_color45");
    color: rgba(255, 255, 255, 0.88);
  }
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover,
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    @include background_color("background_color45");
    @include font_color("font_color26");
    .svg-icon {
      @include font_color("font_color20");
    }
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    @include background_color("background_color15");
    @include font_color("font_color17");
    border-left: 4px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    width: calc(100% - 32px);
    border-radius: 8px;
    // @include border_color("border_color4");
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    border-left: 4px solid rgba(0, 0, 0, 0);
    padding-left: 28px !important;
    width: calc(100% - 32px);
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    position: relative;
    left: 16px;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a {
    @include font_color("font_color22");
    &:hover {
      color: #ffcc55 !important;
    }
  }
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover {
    @include font_color("font_color17");
    font-weight: bold;
  }
  .ant-input {
    @include background_color("background_color2");
    @include font_color("font_color2");
    @include border_color("border_color5");
  }
  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    @include font_color("font_color3");
  }
  .ant-input-search-icon {
    @include font_color("font_color15");
  }
  .date-container .ant-input {
    background: none !important;
    // @include font_color("font_color3");
    border: none !important;
  }

  .ant-pagination-item a,
  .ant-pagination-prev a,
  .ant-pagination-next a {
    @include font_color("font_color3");
  }
  .ant-pagination-item-active {
    background: none;
    border: none;
    a {
      @include font_color("font_color5");
    }
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    @include font_color("font_color5");
  }
  .ant-pagination-item-ellipsis {
    @include font_color("font_color5");
  }
  .ant-calendar-picker-clear {
    // background: none;
    border-radius: 50%;
    font-size: 18px;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    @include background_color("background_colo34");
    @include font_color("font_color41");
    right: 8px;
    > svg {
    }
  }
  .ant-calendar-picker-icon {
    font-size: 18px;
    margin-top: -9px;
    margin-left: -10px;
    @include font_color("font_color3");
  }
  .ant-table-placeholder {
    @include font_color("font_color3");
    border: none;
  }
  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    border-radius: 4px !important;
    @include background_color("background_color2");
  }
  // border ---
  .border {
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid;
      @include border_color("border_color34");
    }
  }
  .hide-select {
    .ant-table-selection-column {
      display: none;
    }
    .ant-table-tbody > tr.ant-table-row-selected td {
      background: #3b3835;
      &:nth-child(2) {
        box-shadow: inset 0px -1px 0px 0px #363a46,
          inset 2px 0px 0px 0px #ffcc55;
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 7px 7px;
    border: none;
    // @include border_color("border_color6");
  }
  .ant-table-thead > tr > th {
    @include background_color("background_color5");
    @include font_color("font_color22");
    height: 50px;
    font-size: 12px;
  }
  .ant-table-header {
    background: none !important;
  }
  .table2 {
    .ant-table-thead > tr > th {
      @include background_color("background_color2");
    }
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      @include background_color("background_color5");
    }
    .ant-table {
      @include font_color("font_color3");
    }
  }
  .table-row-active {
    @include background_color("background_color42");
  }
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    @include background_color("background_color42");
  }

  .no-hover
    .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: none !important;
  }
  .ant-table-tbody .rowActive {
    @include background_color("background_color42");
  }

  // tabs

  .ant-tabs {
    @include font_color("font_color3");
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab:hover {
    @include font_color("font_color1");
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 0;
  }
  .ant-tabs-ink-bar {
    @include background_color("background_color15");
  }
  .ant-tabs-bar {
    border: none;
  }
  .ant-tabs-nav-container {
    @include background_color("background_color16");
    padding: 0 16px;
    border-radius: 4px;
  }
  .no-background .ant-tabs-nav-container {
    background: none !important;
  }
  .no-background .ant-tabs-ink-bar {
    background: none !important;
  }

  // 按钮样式修改
  .ant-radio-button-wrapper {
    @include background_color("background_color2");
    @include border_color("border_color1");
    @include font_color("font_color3");
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    @include background_color("background_color4");
    @include font_color("font_color1");
    @include border_color("border_color1");
    box-shadow: none !important;
  }

  // 下拉框
  .ant-select-selection {
    @include background_color("background_color61");
    @include border_color("border_color15");
    border-radius: 8px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    @include font_color("font_color52");
    @include background_color("background_color87");
    @include border_color("border_color30");
    border-radius: 4px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    @include font_color("font_color52");
  }
  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 7px;
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color20");
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    @include font_color("font_color38");
  }
  .ant-select,
  .ant-select-arrow {
    @include font_color("font_color22");
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }

  // 联动选择框
  .ant-cascader-picker {
    @include background_color("background_color2");
    @include border_color("border_color1");
    @include font_color("font_color3");
    border-radius: 8px;
  }
  .ant-cascader-input {
    border: none;
    border-radius: 8px;
  }
  .ant-cascader-picker:focus .ant-cascader-input {
    box-shadow: none;
  }
  .ant-cascader-picker-arrow {
    @include font_color("font_color22");
  }
  .ant-cascader-picker-label {
    @include font_color("font_color21");
  }
  .ant-cascader-picker-clear {
    background: none;
    @include font_color("font_color22");
  }

  // 单选框
  .ant-radio-inner {
    background: none !important;
    border-width: 2px;
    @include border_color("border_color7");
  }
  .ant-radio-checked .ant-radio-inner {
    @include border_color("border_color4");
  }
  .ant-radio-inner::after {
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
  }
  .ant-radio-wrapper {
    @include font_color("font_color3");
    line-height: 32px;
  }
  .ant-radio-wrapper-checked.ant-radio-wrapper {
    @include font_color("font_color1");
  }

  // 多选框
  .ant-checkbox-wrapper {
    @include font_color("font_color52");
  }
  .ant-checkbox-wrapper-checked.ant-checkbox-wrapper {
    @include font_color("font_color1");
  }
  .ant-checkbox-inner {
    background: none !important;
    border-width: 1px;
    border-radius: 4px;
    @include border_color("border_color35");
  }
  .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    @include background_color("background_color15");
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    @include border_color("border_color1");
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    @include border_color("border_color4");
  }
  .ant-checkbox-inner::after {
    top: 42%;
    left: 19%;
  }

  //修改placeholder的默认颜色：
  input::-webkit-input-placeholder {
    @include font_color("font_color28");
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    @include font_color("font_color28");
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    @include font_color("font_color28");
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    @include font_color("font_color28");
  }
  .ant-input:focus {
    @include border_color("border_color1");
    box-shadow: none !important;
  }

  // side
  .ant-menu-submenu-title {
    .svg-icon {
      margin-right: 10px;
      // opacity: 0.4;
      color: rgba(255, 255, 255, 0.88);
      position: relative;
      top: 1px;
      transition: all 0.2s;
    }
  }
  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      color: #ffca5c;
      .svg-icon {
        color: #ffca5c;
      }
    }
  }
}
</style>
<style lang="less" scoped>
main {
  width: 1000px;
}

/deep/ .ant-layout-content {
  height: calc(100vh - 64.5px) !important;
  min-height: 620px !important;
  overflow-y: auto;
}
/deep/ .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
/deep/ .ant-menu-vertical .ant-menu-item:not(:last-child),
/deep/ .ant-menu-vertical-left .ant-menu-item:not(:last-child),
/deep/ .ant-menu-vertical-right .ant-menu-item:not(:last-child),
/deep/ .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0 !important;
}
/deep/ .ant-menu-vertical .ant-menu-item,
/deep/ .ant-menu-vertical-left .ant-menu-item,
/deep/ .ant-menu-vertical-right .ant-menu-item,
/deep/ .ant-menu-inline .ant-menu-item,
/deep/ .ant-menu-vertical .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-left .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-right .ant-menu-submenu-title,
/deep/ .ant-menu-inline .ant-menu-submenu-title {
  margin: 0 !important;
}
/deep/ .ant-menu-item.ant-menu-item-selected {
  margin: 0 !important;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 4px !important;
}
// 表格选中颜色
/deep/ .ant-table-row {
  cursor: pointer;
}
/deep/ .no-hover .ant-table-row {
  cursor: auto;
}
/deep/ .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
/deep/ .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  color: #fff;
}

//a-tabs，加tabs-box显示灰色状态
/deep/ .ant-tabs.tabs-box .ant-tabs-bar {
  border-color: #4c4e55;
  padding: 20px 20px 0 20px;
}
/deep/ .ant-tabs.tabs-box .ant-tabs-tab {
  background: transparent !important;
  border-color: #4c4e55 !important;
}
/deep/ .ant-tabs.tabs-box > .ant-tabs-bar .ant-tabs-tab-active {
  background: #4c4e55 !important;
}

// a-month-picker,加picker-box显示透明背景色
/deep/ .picker-box .ant-input {
  color: #fff;
  background-color: transparent;
}
/deep/ .picker-box .ant-calendar-picker-icon {
  color: #fff;
}

// table no-data
/deep/ .ant-table-placeholder {
  background: none;
  color: #fff;
}
/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-pagination-item-ellipsis {
  color: #13c2c2 !important;
}
</style>
<style lang="less">
.ant-menu-dark .ant-menu-vertical.ant-menu-sub.ant-menu-sub {
  width: 180px;
  background-color: #20232c !important;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #20232c !important;
  color: #ffcc55 !important;
}
.ant-menu-dark .ant-menu-item-selected > a {
  color: #ffcc55 !important;
}
.ant-menu-dark .ant-menu-item {
  margin: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant-menu-dark .ant-menu-item:hover {
  color: #ffcc55 !important;
  background-color: #2b2e38 !important;
}
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.88) !important;
}
.ant-menu-dark .ant-menu-item > a:hover {
  color: #ffcc55 !important;
  background-color: transparent !important;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 1 !important;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background: rgba(255, 255, 255, 0.88) !important;
}
.ant-menu-dark .ant-menu-submenu.ant-menu-submenu-active {
  .ant-menu-submenu-arrow {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out 0.8s;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/_handle.scss";
// ---------- 新版聚金塔样式 ----------
// 按钮样式
.new-aft-btn.ant-btn-primary {
  @include background_color("background_color13");
  @include border_color("background_color13");
  @include font_color("background_color45");
  border-radius: 8px;
  &.ant-btn-lg {
    font-size: 14px;
  }
}
.new-aft-btn.ant-btn-primary:hover,
.new-aft-btn.ant-btn-primary:focus {
  background: rgba($color: #ffca5c, $alpha: 0.9) !important;
}
.new-aft-btn.ant-btn-default {
  @include background_color("background_color2");
  @include border_color("background_color2");
  @include font_color("font_color20");
  border-radius: 8px;
  &.ant-btn-lg {
    font-size: 14px;
  }
}
.new-aft-btn.ant-btn-default:hover,
.new-aft-btn.ant-btn-default:focus {
  border-color: rgba($color: #2b2f3b, $alpha: 0.5) !important;
  background: rgba($color: #2b2f3b, $alpha: 0.5) !important;
}

// input样式
.new-aft-input.ant-input {
  @include background_color("background_color2");
  @include border_color("background_color2");
  &:focus {
    box-shadow: 0 0 0 2px rgba($color: #ffca5c, $alpha: 0.5);
  }
}

// select样式
.new-aft-select.ant-select {
  @include font_color("font_color20");
  .ant-select-selection {
    @include background_color("background_color2");
    @include border_color("background_color2");
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color20");
  }
  .ant-select-selection__choice {
    background: #1f1f1f;
    border-color: #1f1f1f;
    @include font_color("font_color58");
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    @include font_color("font_color59");
  }
  .ant-select-arrow-icon {
    @include font_color("font_color59");
  }
  input::-webkit-input-placeholder {
    @include font_color("font_color59");
  }
}
#popContainer .new-aft-select.ant-select .ant-select-selection__placeholder {
  @include font_color("font_color59");
}
.new-aft-select.ant-select-open .ant-select-selection,
.new-aft-select .ant-select-selection:focus,
.new-aft-select .ant-select-selection:active,
.new-aft-select.ant-select-focused .ant-select-selection {
  box-shadow: 0 0 0 2px rgba($color: #ffca5c, $alpha: 0.5) !important;
}
.ant-select-dropdown-menu-item-selected {
  .ant-select-selected-icon.anticon-check {
    @include font_color("font_color4");
  }
}

// modal样式
.new-aft-modal {
  .ant-modal-header {
    padding: 24px;
    border-bottom: none;
    @include background_color("background_color91");
  }
  .ant-modal-close-x {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .ant-modal-content {
    @include background_color("background_color91");
  }
  .ant-modal-body {
    padding: 0 24px;
    @include background_color("background_color91");
  }
  .ant-modal-footer {
    padding: 20px 24px;
    border-top: none;
    @include background_color("background_color91");
  }
}

// 翻页样式
.new-aft-pagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include background_color("background_color50");
    border-radius: 8px;
    a {
      background: none;
      border: none;
    }
  }

  .ant-pagination-item {
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include background_color("background_color50");
    border-color: #2b2f3b;
    border-radius: 8px;
    &:focus,
    &:hover {
      border: none;
      a {
        @include font_color("font_color4");
      }
    }
  }

  .ant-pagination .ant-pagination-item-active {
    @include background_color("background_color13");
    a {
      @include font_color("font_color41");
    }
  }

  .ant-pagination-item-active {
    @include background_color("background_color13");
    a {
      @include font_color("font_color41");
    }
  }
}

// switch
.new-aft-switch {
  &.ant-switch {
    background: rgba(255, 255, 255, 0.3);
  }
  &.ant-switch-checked {
    @include background_color("background_color13");
  }
}
// ---------- 新版聚金塔样式 ----------
</style>
