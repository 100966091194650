<template>
  <a-layout-header :class="[headerTheme, 'admin-header']">
    <div :class="['admin-header-wide', layout, pageWidth]">
      <!-- <router-link v-if="isMobile || layout === 'head'" to="/" :class="['logo', isMobile ? null : 'pc', headerTheme]">
        <img width="32" src="@/assets/img/logo.png" />
        <h1 v-if="!isMobile">{{systemName}}</h1>
      </router-link> -->
      <!-- <a-divider v-if="isMobile" type="vertical" /> -->
      <!-- <a-icon v-if="layout !== 'head'" class="trigger trigger-icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapse"/> -->
      <!-- <div
        v-if="layout !== 'side' && !isMobile"
        class="admin-header-menu"
        :style="`width: ${menuWidth};`"
      >
        <i-menu
          class="head-menu"
          :theme="headerTheme"
          mode="horizontal"
          :options="menuData"
          @select="onSelect"
        />
      </div> -->
      <img
        src="@/assets/img/logo6.png"
        v-if="logoType == 1"
        alt=""
        style="height: 54px"
      />
      <img :src="customLogo" v-if="logoType == 3" alt="" style="height: 54px" />
      <div :class="['admin-header-right', headerTheme]">
        <header-theme v-show="false"></header-theme>
        <template v-if="!customerTag">
          <a-button
            :class="['total-button', tooltipModalShow ? 'active' : '']"
            @click="setTooltipModal(true)"
          >
            <svg-icon icon-class="total-tooltip" class="icon" />全站指标说明
          </a-button>
          <a-button
            :class="['total-button', areaRouteShow ? 'active' : '']"
            @click="areaRouteShow = true"
          >
            <svg-icon icon-class="total-area" class="icon" />全站地图
          </a-button>
        </template>
        <header-avatar class="header-item" />
        <!-- <a-dropdown class="lang header-item">
            <div>
              <a-icon type="global"/> {{langAlias}}
            </div>
            <a-menu @click="val => setLang(val.key)" :selected-keys="[lang]" slot="overlay">
              <a-menu-item v-for=" lang in langList" :key="lang.key">{{lang.key.toLowerCase() + ' ' + lang.name}}</a-menu-item>
            </a-menu>
          </a-dropdown> -->
      </div>
    </div>
    <TotalTooltip />
    <header-route
      :areaRouteShow="areaRouteShow"
      @close="areaRouteShow = false"
    ></header-route>
  </a-layout-header>
</template>

<script>
import HeaderSearch from "./HeaderSearch";
import HeaderNotice from "./HeaderNotice";
import HeaderAvatar from "./HeaderAvatar";
import HeaderTheme from "./HeaderTheme";
import HeaderRoute from "./HeaderRoute";
import IMenu from "@/components/menu/menu";
import TotalTooltip from "@/components/TotalTooltip/TotalTooltip";
import { mapState, mapMutations } from "vuex";
import { getLogoType, getCustomerTag } from "@/utils/auth";

export default {
  name: "AdminHeader",
  components: {
    IMenu,
    HeaderAvatar,
    HeaderTheme,
    HeaderRoute,
    HeaderNotice,
    HeaderSearch,
    TotalTooltip,
  },
  props: ["collapsed", "menuData"],
  data() {
    return {
      langList: [
        { key: "CN", name: "简体中文", alias: "简体" },
        { key: "HK", name: "繁體中文", alias: "繁體" },
        { key: "US", name: "English", alias: "English" },
      ],
      searchActive: false,
      areaRouteShow: false,
      logoType: getLogoType(),
      customerTag: getCustomerTag(),
    };
  },
  computed: {
    ...mapState("setting", [
      "theme",
      "isMobile",
      "layout",
      "systemName",
      "lang",
      "pageWidth",
      "tooltipModalShow",
    ]),
    customLogo() {
      return localStorage.jjt_custom_logo;
    },
    headerTheme() {
      if (
        this.layout == "side" &&
        this.theme.mode == "dark" &&
        !this.isMobile
      ) {
        return "light";
      }
      return this.theme.mode;
    },
    langAlias() {
      let lang = this.langList.find((item) => item.key == this.lang);
      return lang.alias;
    },
    menuWidth() {
      const { layout, searchActive } = this;
      const headWidth = layout === "head" ? "100% - 188px" : "100%";
      const extraWidth = searchActive ? "600px" : "400px";
      return `calc(${headWidth} - ${extraWidth})`;
    },
  },
  methods: {
    toggleCollapse() {
      this.$emit("toggleCollapse");
    },
    onSelect(obj) {
      this.$emit("menuSelect", obj);
    },
    ...mapMutations("setting", ["setLang", "setTooltipModal"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.trigger-icon {
  @include font_color("font_color1");
}
.total-button {
  background: none;
  @include font_color("font_color22");
  @include border_color("border_color18");
  font-size: 12px;
  border: 1px solid;
  padding: 0 20px;
  height: 36px;
  margin: 14px 34px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  .icon {
    margin-right: 10px;
    font-size: 20px;
  }
}
.total-button:hover,
.total-button.active {
  @include font_color("font_color20");
  @include border_color("border_color17");
  background: none !important;
}
</style>

<style lang="less" scoped>
@import "index";
</style>
